<template>
  <div style=" background: #f4f5f7;">
    <div class="showcase">
      <p class="main-header">Bionluk'ta Yapıldı 🧙‍</p>
      <div class="tags">
        <p v-for="tag in tags" @click="selectTag(tag)" :class="tag === selectedTag ? 'tag-selected' : 'tag'" >{{tag}}</p>
      </div>

      <div style="margin-left: -27px">
        <div class="cardBoxContainer">
          <div v-for="portfolio in items" :key="portfolio.uuid" class="portfolio-box">
            <div class="head-section">
              <router-link
                :to="'/'+portfolio.username">
                <img class="avatar" :src="portfolio.avatar_url">
              </router-link>
              <div style=" margin-left: 6px; display: flex; height:40px; flex-direction: column; justify-items: center">
                <p class="title">
                    <a :href="specifyPostUrl(portfolio.uuid)" @click.stop.prevent="preventClicking($event)" @click="showPortfolio(portfolio.uuid)">
                    {{portfolio.name}}
                    </a>
                  </p>
                <p class="sub-title" v-if="portfolio.rating_user.id">{{portfolio.username}}, <router-link
                  :to="'/'+portfolio.rating_user.username">{{portfolio.rating_user.username}}</router-link> için yaptı.</p>
                <p v-else class="sub-title">{{portfolio.category_name}}</p>
              </div>
            </div>
            <a :href="specifyPostUrl(portfolio.uuid)" @click.stop.prevent="preventClicking($event)" style="position: relative; display: block" class="portfolio-img" @click="showPortfolio(portfolio.uuid)">
              <img :src="portfolio.media.image_url">
              <div v-show="portfolio.media.file_type === 'audio'" class="sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
              </div>
              <div v-show="portfolio.media.file_type === 'video'" class="sound-icon-container">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
              </div>
            </a>
            <div class="foot-section">
              <div style="display: flex">
                <img src="https://gcdn.bionluk.com/site/cicons/ic-like-fill.svg" onload="SVGInject(this)" class="like-icon"/>
                <span>{{portfolio.fav_count}}</span>
                <img src="https://gcdn.bionluk.com/site/cicons/ic-messages.svg" onload="SVGInject(this)" class="like-icon"/>
                <span>{{portfolio.comment_count}}</span>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div v-if="loading" style="margin-left: auto; margin-right: auto;">
        <div style="display: flex;flex-wrap: wrap">
          <div v-for="x in 8" class="card-container">

            <div class="card-detail">

              <div class="card-detail-user-container ">
                <div class="card-detail-user">
                  <div class="card-detail-user-image card"></div>
                </div>
                <div style="display: flex; flex-direction: column">
                  <div class="card-detail-user-name card">
                  </div>
                  <div class="card-detail-user-name card" style="height: 10px; margin-top: 5px; width: 120px">
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div class="card-image-container card">
            </div>

            <div class="card-like-currency">
              <div class="card-like-icon card"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="see-more-div" @click="getItems(selectedTag)"
           v-if="!loading && showSeemore">
        <p style="color: #2d3640; font-size: 18px; font-weight: 500"><span style="font-size: 24px; line-height: 1">+</span> Daha fazla göster</p>
      </div>


    </div>
  </div>
</template>

<script>

	export default {
		data() {
			return {
			  limit:16,
        offset:0,
			  tags:['En Beğenilen', 'En çok yorumlananlar','Seslendirme','Logo Tasarımı','Video & Animasyon','Diğer Tasarımlar'],
			  selectedTag:'',
        items: [],

        loading:false,
        showSeemore:true,
      }
		},
    methods:{

      specifyPostUrl(ID) {
        return /freelancer-vitrin/ + ID;
      },

      preventClicking(event) {
        event.returnValue = false;
        return false;
      },

      showPortfolio(uuid) {
        // this.$store.state.showCase.data = portfolio;
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.PORTFOLIO, info: {uuid: uuid, calledFrom:'today_box', allItems:this.items}, description: 'keepOldModal'});
      },
		  selectTag(tag){
		    if(this.loading) return false;
		    this.items = [];
		    this.offset = 0;
		    if(tag === this.selectedTag){
		      this.selectedTag = null;
		      this.getItems(null);
        } else {
          this.selectedTag = tag;
          this.getItems(tag);
        }
      },
      getItems(type, resetOffset = false){
		   if(resetOffset) this.offset = 0;

		   this.loading = true;
		    let postObj = {
		      limit:this.limit,
          offset:this.offset,
          type
        };
        this.api.feeds.getShowCaseItems(postObj, this.$Progress)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.items = this.items.concat(result.data);
              if(!result.data || result.data.length === 0){
                this.showSeemore = false;
              } else {
                this.offset = this.offset + this.limit;
              }
              this.loading = false;
            } else {
              this.$toasted.error(result.message);
              this.loading = false;
            }
          })
          .catch(err => {
            this.$toasted.error(err);
            this.loading = false;
          })
      }

    },

    beforeDestroy () {
      this.EventBus.$off('updatePortfolio');
    },
    created () {
		  this.getItems(null,true);

      this.EventBus.$on('updatePortfolio', payload => {

        let uuid = payload.uuid;
        let what = payload.what;
        let type = payload.type;

        if(what==='comment' || what==='fav_count'){
          this.items.forEach(function (portfolio) {
            if (portfolio.uuid === uuid) {
              if(what==='comment'){
                if(type === 'plus') portfolio.comment_count += 1;
                else  portfolio.comment_count -= 1;
              }


              if(what==='fav_count'){
                if(type === 'plus') portfolio.fav_count += 1;
                else  portfolio.fav_count -= 1;
              }

            }
          });
        }


      });
    }
  }

</script>

<style scoped lang="scss">
  .see-more-div {
    margin:30px auto 60px;
    text-align: center;
    cursor: pointer;
    font-weight: 600;
  }

  .see-more-div-loader {
    width: 25px;
    height: 25px;
    margin:30px auto 60px;
  }


  .card-container {
    display: flex;
    flex-direction: column;


    margin-left: 26px;
    margin-bottom: 30px;
    width: 277px;
    height: 274px;

    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    &:nth-child(4n+1) {
      margin-left: 0;
    }
  }

  .card-image-container {
    height: 148px;
    background-color: #fafbfc;
    border-bottom: solid 1px #dfe4ec;
  }

  .card-detail-container {
    padding: 15px;
  }

  .card-detail-header-large {
    width: 234px;
    height: 16px;
    background-color: #e1e6ed;
    margin-bottom: 4px;
  }

  .card-detail-header-small {
    width: 117px;
    height: 16px;
    background-color: #e1e6ed;
  }

  .card-detail-user-image {
    width: 40px;
    height: 40px;
    background-color: #dfe4ec;
    border-radius: 50%;
  }

  .card-detail-user-name {
    width: 150px;
    height: 12px;
    margin-left: 10px;
    background-color: #e1e6ed;

  }

  .card-detail-currency {
    width: 63px;
    height: 16px;
    margin-left: 10px;
    background-color: #e1e6ed;
  }

  .card-detail-user-container {
    display: flex;
    align-items: center;
    margin: 2px 15px 0px;
  }

  .card-detail-user {
    height: 66px;
    display: flex;
    align-items: center;
  }

  .card-like-icon {
    width: 55px;
    height: 24px;
    background-color: #e1e6ed;
  }

  .card-currency-icon {
    width: 42px;
    height: 20px;
    background-color: #bdc5d0;
  }

  .card-like-currency {
    display: flex;
    align-items: center;
    margin: 13px 15px;
  }

  .card {
    animation-name: loading;
    animation-duration: 2.5s;
    animation-iteration-count: infinite;
  }

  .service-label {
    padding-left: 10px;
  }

  @keyframes loading {
    10% {
      background-color: #dfe4ec;
    }
    2% {
      background-color: #fafbfc;
    }
  }


  .portfolio-box{

    margin-left: 26px;
    margin-bottom: 30px;
    width: 277px;
    height: 274px;

    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
    border: solid 1px #eaedf2;
    background-color: #ffffff;

    .head-section{
      display: flex;
      align-items: center;
      height: 66px;
      margin-left: 11px;
      .title{
        margin-top: 1px;
        font-size: 14px;
        font-weight: 600;
        color: #2d3640;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 210px;
        height: 18px;
        display: block;
      }
      .avatar{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 1px solid #eaeaea;
      }
      .sub-title{
        margin-top: 2px;
        font-size: 12px;
        font-weight: 300;
        color: #8b95a1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 210px;
        height: 14px;
        a{
          font-size: 12px;
          font-weight: 600;
          color: #fd4056;
          &:hover{
            text-decoration: underline;
            color: #fd4056!important;
          }
        }
      }
    }

    .portfolio-img{

      position: relative;
      cursor: pointer;
      height: 158px;
      img{
        width: 100%;
      }
    }

    .foot-section{

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 20px;
      margin-right: 20px;
      height: 48px;

      font-size: 16px;
      font-weight: 600;
      color: #8b95a1;

      span{
        font-size: 16px;
        font-weight: 600;
        color: #8b95a1;
        padding-left: 5px;
        padding-right: 15px;
        line-height: 0.9;

      }
      .like-icon /deep/ {
        cursor: pointer;
        width: 16px;
        height: 16px;
        path {
          fill: #bfc8d2!important;
        }
      }

      .trash-icon /deep/ {
        cursor: pointer;
        width: 20px;
        height: 20px;
        path {
          fill: #bfc8d2!important;
          &:hover{
            fill: #2d3640!important;
          }
        }
      }

      .trash-icon:hover{
        path {
          fill: #2d3640!important;
        }
      }

      .edit-icon /deep/ {
        cursor: pointer;
        width: 20px;
        height: 20px;
        path {
          fill: #bfc8d2!important;
          &:hover{
            fill: #2d3640!important;
          }
        }
      }
      .edit-icon:hover{
        path {
          fill: #2d3640!important;
        }
      }

    }
  }


  .cardBoxContainer{

    transition: transform 0.5s ease 0s;
    visibility: visible;
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y:hidden;
    .cardBoxFlexC{
      display: flex;

    }


    .pBox{
      width: 277px;
      height: 274px;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(45, 54, 64, 0.05);
      border: solid 1px #eaedf2;
      background-color: #ffffff;

      position: relative;
      display: flex;


      flex-direction: column;
      align-items: center;
      margin-left: 28px;
      margin-bottom: 30px;

      .header{

        display: flex;
        align-items: center;
        height: 67px;
        width: 100%;

      }

      .media{
        width: 277px; height: 158px;
        cursor: pointer;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      .avatar{
        margin-left: 11px;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 1px solid #eaeaea;
      }
      .username-p{

        font-size: 11px;
        font-weight: 300;
        color: #5e6b79;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 14px;
        max-width: 200px;

        a{
          font-size: 11px;
          font-weight: 600;
          color: #fa4a5e;
          &:hover{
            text-decoration: underline;
          }
        }
      }

      .title{
        font-weight: 600;
        color: #2d3640;
        font-size: 14px;
      }


    }

    .playIcon {
      cursor: pointer;
      z-index: 9;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -130%);
      height: 42px;
      width: 42px;
      border: 2px solid white;
      background-color: white;
      border-radius: 50%;
      img {
        height: 42px;
        width: 42px;
      }
    }

  }

  .showcase{
    width: 1195px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 100px;
  }
  .main-header{
    margin-bottom: 40px;
    font-size: 34px;
    font-weight: 600;
    color: #2d3640;
  }

  .tags{
    margin-bottom: 30px;
    display: flex;
    .tag{
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      height: 32px;
      padding-bottom: 2px;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 4px;
      border: solid 1px #8b95a1;
      font-size: 13px;
      font-weight: normal;
      color: #8b95a1;
      &:hover{
        border: solid 1px #26ca9a;
        color: #26ca9a;
      }
    }

    .tag-selected{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 15px;
      height: 32px;
      border-radius: 4px;
      background-color: #26ca9a;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 2px;
      border: solid 1px #26ca9a;
      font-size: 13px;
      font-weight: normal;
      color: #ffffff;

    }
  }
</style>
